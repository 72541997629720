import { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import AppRoutes from './routes/routes';
import { AuthProvider } from './components/Private/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';

declare global {
  interface Window {
    jQuery: any;
  }
}

const queryClient = new QueryClient();

const SupportBoardLoader = () => {
  const location = useLocation();

  const loadSupportBoardScript = useCallback(() => {
    const script = document.createElement('script');
    script.id = 'sbinit';
    script.src = 'https://suporte.integrameli.com.br/js/main.js';
    script.async = true;
    script.onload = () => {
      console.log('Support Board loaded');
    };
    document.body.appendChild(script);
  }, []);

  const loadJQueryAndSupportBoard = useCallback(() => {
    if (!window.jQuery) {
      const jQueryScript = document.createElement('script');
      jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
      jQueryScript.async = true;
      jQueryScript.onload = loadSupportBoardScript;
      document.body.appendChild(jQueryScript);
    } else {
      loadSupportBoardScript();
    }
  }, [loadSupportBoardScript]);

  useEffect(() => {
    if (location.pathname !== '/login') {
      loadJQueryAndSupportBoard();
    }

    return () => {
      const supportBoardScript = document.getElementById('sbinit');
      if (supportBoardScript) {
        document.body.removeChild(supportBoardScript);
      }

      const jQueryScript = document.querySelector('script[src="https://code.jquery.com/jquery-3.6.0.min.js"]');
      if (jQueryScript) {
        document.body.removeChild(jQueryScript);
      }
    };
  }, [location.pathname, loadJQueryAndSupportBoard]);

  return null;
};

const App = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <SupportBoardLoader />
          <AppRoutes />
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
